<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-actions class="text-center">
        </md-card-actions>
        <md-card-content>
          <div class="md-layout">
              <div class="md-layout-item md-size-33">
                <md-field>
                    <md-select class="box-textbox" placeholder="Search By Type" v-model="search_by_type">
                      <md-option value="">Search By Type</md-option>
                      <md-option value="User Account">User Account</md-option>
                      <md-option value="Policy">Policy</md-option>
                      <md-option value="Device">Device</md-option>
                      <md-option value="QR Code">QR Code</md-option>
                      <md-option value="Application Users">Application Users</md-option>
                      <md-option value="Application">Application</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field>
                    <md-select class="box-textbox" placeholder="Search By Type" v-model="search_by_sub_type">
                      <md-option value="">Search By Sub Type</md-option>
                      <md-option v-if="['','Policy', 'QR Code', 'Application Users'].indexOf(search_by_type) !== -1" value="Create">Create</md-option>
                      <md-option v-if="['','Policy', 'QR Code', 'Application Users'].indexOf(search_by_type) !== -1" value="Update">Update</md-option>
                      <md-option value="Delete">Delete</md-option>

                      <md-option v-if="['','Policy'].indexOf(search_by_type) !== -1" value="Apply">Apply</md-option>
                      <md-option v-if="['','Policy'].indexOf(search_by_type) !== -1" value="Copy">Copy</md-option>
                      <md-option v-if="['','Policy'].indexOf(search_by_type) !== -1" value="Appliation Add">Appliation Add</md-option>
                      <md-option v-if="['','Policy'].indexOf(search_by_type) !== -1" value="Appliation Delete">Appliation Delete</md-option>
                      <md-option v-if="['','Policy'].indexOf(search_by_type) !== -1" value="WiFi Add">WiFi Add</md-option>
                      <md-option v-if="['','Policy'].indexOf(search_by_type) !== -1" value="WiFi Delete">WiFi Delete</md-option>
                      <md-option v-if="['','Policy'].indexOf(search_by_type) !== -1" value="Application Enable">Application Enable</md-option>
                      <md-option v-if="['','Policy'].indexOf(search_by_type) !== -1" value="Application Disable">Application Disable</md-option>
                      <md-option v-if="['','Policy'].indexOf(search_by_type) !== -1" value="Content Add">Content Add</md-option>
                      <md-option v-if="['','Policy'].indexOf(search_by_type) !== -1" value="Content Delete">Content Delete</md-option>
                      
                      <md-option v-if="['','Device'].indexOf(search_by_type) !== -1" 
                        value="Exit KIOSK">Exit KIOSK</md-option>
                      <md-option v-if="['','Device'].indexOf(search_by_type) !== -1" value="Issue Command">Issue Command</md-option>
                      <md-option v-if="['','Device'].indexOf(search_by_type) !== -1" value="Move Device">Move Device</md-option>
                      <md-option v-if="['','Device'].indexOf(search_by_type) !== -1" value="Assign Device User">Assign Device User</md-option>

                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Change Password">Change Password</md-option>
                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Two Step Setting">Two Step Setting</md-option>
                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Update User Profile">Update User Profile</md-option>

                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Register">Register</md-option>
                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Verify Email">Verify Email</md-option>
                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Login">Login</md-option>
                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Logout">Logout</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field>
                  <md-select class="box-textbox" placeholder="Profile Name" v-model="search_profile_id">
                      <md-option value="">Select Profile</md-option>
                      <md-option v-for="profile in user_profiles" v-bind:key="profile._id" :value="profile._id">
                        {{ profile.profile_name }}
                      </md-option>
                  </md-select>
                </md-field>
              </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
                <md-field>
                  <md-input type="text" class="box-textbox" placeholder="Serial Number" v-model="search_serial_number"></md-input>
                </md-field>
            </div>
            <div class="md-layout-item md-size-25">
                  <md-button class="md-primary" :disabled="isDisabled" style="margin-top: 10px;margin-left: -20px;" @click="GetAuditList()">Search</md-button> 
              </div>
          </div>
          <hr>
          <md-table
            :value="queriedData"
            class="paginated-table table-striped table-hover" 
            table-header-color="green"
            md-fixed-header
          >
          <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Type"><b>{{ item.type }}</b></md-table-cell>
              <md-table-cell md-label="Sub Type"><b>{{ item.sub_type }}</b>
              <span v-if="item.sub_type == 'Login'"> <br> {{ item.userAgent }}</span>
              </md-table-cell>
              <md-table-cell md-label="IP Detail">
                <p v-if="item.ipAddressDetail">{{ item.ipAddressDetail }}</p>
                <p v-else>-</p>
              </md-table-cell>
              <md-table-cell md-label="Message">{{ item.message }}</md-table-cell>
              <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import UserDataService from "../../services/UsersDataService";
import DeviceProceDataService from "../../services/DeviceProceDataService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      user_profiles: [],
      search_profile_id:"",
      search_serial_number: "",

      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],

      //Search Paramters
      search_by_type: "",
      search_by_sub_type: "",
    };
  },
  methods: {
    GetAuditList() {
        let data = {
          type: this.search_by_type,
          sub_type: this.search_by_sub_type,
          profile_id: this.search_profile_id,
          serial_number: this.search_serial_number,
      }
      UserDataService.GetAuditList(data)
        .then((response) => {
          this.tableData = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },

    GetDeviceProfileList() {
      DeviceProceDataService.GetDeviceProfileList({})
        .then((response) => {
          if (response.data.data) {
            this.user_profiles = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.GetAuditList();
    this.GetDeviceProfileList();
  },
};
</script>
<style scoped>
</style>
